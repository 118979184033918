import dateFormat from 'dateformat'

export const CALENDAR_STEP_SIZES = {
	DAY: 86400000,
	HOUR: 3600000,
	MINUTE: 60000,
}

export const getCurrentDate = () => ({
	date: (new Date()),
	number: (new Date()).getTime(),
})

export const getCurrentStartOfDay = () => {
	const date = new Date()
	date.setHours(0, 0, 0, 0)
	return {
		date: date,
		number: date.getTime(),
	}
}

export const getNumDaysFromToday = num => {
	const day = getCurrentStartOfDay().date
	day.setDate(day.getDate() + num)
	return {
		date: day,
		number: day.getTime(),
	}
}

export const getNumDaysFromNum = (num, numDays) => getNumDaysFromDate(numToDate(num), numDays)

export const getNumDaysFromDate = (date, numDays) => {
	const newDate = new Date(date)
	newDate.setDate(newDate.getDate() + numDays)
	return {
		date: newDate,
		number: newDate.getTime(),
	}
}

export const numToDate = dbTime => {
	let date = new Date()
	date.setTime(dbTime)
	return date
}

export const dateToNum = date => date.getTime()

export const numToOffsetDays = num => (num - getCurrentStartOfDay().number) / CALENDAR_STEP_SIZES.DAY

export const propsToDateArray = ({firstDay, numCols, stepSize}) => {

	// https://stackoverflow.com/questions/11887934/how-to-check-if-the-dst-daylight-saving-time-is-in-effect-and-if-it-is-whats

	const dates = []
	const firstDate = (new Date()).setTime(firstDay)
	for (let i = 0; i < numCols; i++) {
		const dateCopy = new Date(firstDate)
		dateCopy.setDate(dateCopy.getDate() + i)
		dates.push(dateCopy)
	}
	return dates
}

export const millisecondsOnScreen = ({numCols, stepSize}) => numCols * stepSize

export const endOfScreen = ({numCols, stepSize, firstDay}) => getNumDaysFromNum(firstDay, numCols).number

export const isStartOfWeek = date => date.getDay() === 0

export const getDayFromEvent = event => {
	const elementsUnderCursor = document.elementsFromPoint(event.clientX, event.clientY)
	for (let element of elementsUnderCursor) {
		if (element.classList.contains("calendar") &&
			element.classList.contains("day")) {
			return parseInt(element.id)
		}
	}
}

export const msToUnits = ms => ({
	days: Math.floor(ms / CALENDAR_STEP_SIZES.DAY),
	hours: Math.floor((ms % CALENDAR_STEP_SIZES.DAY) / CALENDAR_STEP_SIZES.HOUR),
	minutes: Math.floor((ms % CALENDAR_STEP_SIZES.HOUR) / CALENDAR_STEP_SIZES.MINUTE),
})

export const unitsToMS = units => (units.days * CALENDAR_STEP_SIZES.DAY) + (units.hours * CALENDAR_STEP_SIZES.HOUR) + (units.minutes * CALENDAR_STEP_SIZES.MINUTE)

export const isRangeCurrent = (startMS, endMS) => getCurrentDate().number >= startMS && getCurrentDate().number <= endMS

export const decimalToPercent = dec => Math.round(Math.max(0, Math.min(1, dec)) * 100)

export const isSameDay = (day1, day2) => {
	return (
		day1.getFullYear() === day2.getFullYear() &&
		day1.getMonth() === day2.getMonth() &&
		day1.getDate() === day2.getDate()
	)
}

// TODO: re-add hours and minutes when relevant
export const getDateTextFromNum = (num, subtractIfWholeDays = 0) => {
	// const date = numToDate(num)
	// if (
	// 	(!date.getHours() && !date.getMinutes())
	// ) {
		return dateFormat(numToDate(num - subtractIfWholeDays), "dddd, mmmm d")
	// }
	// return dateFormat(numToDate(num), 'dddd, mmmm d "at" h:MM TT')
}

export const getTimeTextFromNum = (num, subtractIfWholeDays=0) => {
	const date = numToDate(num)
	return dateFormat(date, "h:MM TT")
}

export const isExactDay = num => num % CALENDAR_STEP_SIZES.DAY === 0
