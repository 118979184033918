import React from 'react'
import { connect } from 'react-redux'
import './Task.css'
import { withDatabase } from '../../database/context'
import { millisecondsOnScreen } from '../../functions/dates'
import withDragNewTask from '../../wrappers/withDragNewTask'
import { compose } from 'redux'
import Task from './Task'

let Tasks = props => {

	const getTask = taskId => props.tasksInStream[taskId]

	// sort tasks by start date
	const sortedTaskIds = Object.keys(props.tasksInStream).sort((a, b) => {
		const startDateB = getTask(a).startDate
		const startDateA = getTask(b).startDate
		if (startDateA > startDateB) {
			return -1
		}
		if (startDateA < startDateB) {
			return 1
		}
		return 0

	// filter out tasks not in view
	}).filter(taskId => {
		const task = getTask(taskId)
		if ((task.startDate + task.duration <= props.calendar.firstDay) ||
			(task.startDate >= props.calendar.firstDay + millisecondsOnScreen(props.calendar))) {
			return false
		}
		return true
	})

	const tracks = []
	for (let taskId of sortedTaskIds) {
		let chosenTrack = null
		for (let track of tracks) {
			let hasConflict = false
			for (let taskIdFromTrack of track) {
				if (getTask(taskId).conflictsWith(getTask(taskIdFromTrack))) {
					hasConflict = true
					break
				}
			}
			if (!hasConflict) {
				chosenTrack = track
				break
			}
		}
		if (chosenTrack) {
			chosenTrack.push(taskId)
		} else {
			tracks.push([taskId])
		}
	}

	return (
		<div
			className="Tasks"
			draggable={true}
			onDragStart={props.onDragStart}
			onDrag={props.onDrag}
			onDragEnd={props.onDragEnd}
			onDoubleClick={props.onClick}
		>
			{tracks.map((track, j) => {
				let curDate = props.calendar.firstDay
				return (
					<div
						key={j}
						className={
							"track" +
							(j === 0 ? " firstTrack" : "") +
							(!props.tempTask && j === tracks.length - 1 ? " lastTrack" : "")
						}
					>
						{track.map((taskId, i) => {
							const lastDate = curDate
							curDate = getTask(taskId).endDate()
							return (
								<Task
									key={taskId}
									taskId={taskId}
									curDate={lastDate}
									isFirstInTrack={i === 0}
									isLastInTrack={i === track.length - 1}
									{...props.toTask}
								/>
							)
						})}
					</div>
				)
			})}
			{!props.tempTask && tracks.length === 0 &&
				<div className="track firstTrack lastTrack" />
			}
			{props.tempTask &&
				<div
					className={
						"track lastTrack" +
						(tracks.length === 0 ? " firstTrack" : "")
					}
				>
					<Task
						curDate={props.calendar.firstDay}
						tempTask={true}
						task={props.tempTask}
						isFirstInTrack={true}
						isLastInTrack={true}
					/>
				</div>
			}
		</div>
	)
}

export const getTasksInStream = (streamId, allTasks) => {
	const tasksInStream = {}
	Object.keys(allTasks)
		.filter(taskId => streamId === allTasks[taskId].streamId.toString())
		.map(taskId => tasksInStream[taskId] = allTasks[taskId])
	return tasksInStream
}

const mapStateToProps = (state, ownProps) => {
	return {
		tasksInStream: getTasksInStream(ownProps.streamId, state.tasks),
		calendar: state.client.calendar,
	}
}

Tasks = connect(
		mapStateToProps
	)(compose(
		withDatabase,
		withDragNewTask,
	)(Tasks))

export default Tasks
