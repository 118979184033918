import React, { useState } from 'react'
import { unitsToMS } from '../../../functions/dates'
import { ConfirmFieldButtons } from '../Parts/Parts'
import NumberPicker from '../Parts/NumberPicker'

const DurationPicker = props => {

	const durationLimits = {
		days: {
			min: 1,
			max: 99,
		},
		hours: {
			min: 0,
			max: 23,
		},
		minutes: {
			min: 0,
			max: 59,
		}
	}

	const [isInvalid, setInvalid] = useState(false)

	const setInvalidDuration = () => {
		if (!isInvalid) {
			setInvalid(true)
		}
	}

	return (
		<React.Fragment>
			<NumberPicker
				unit="days"
				value={props.duration.days}
				min={durationLimits.days.min}
				max={durationLimits.days.max}
				setInvalid={setInvalidDuration}
				onChange={days => {
					setInvalid(false)
					props.changeDuration(unitsToMS({
						...props.duration,
						days,
					}))
				}}
			/>
			{/* <NumberPicker
				unit="hours"
				value={props.duration.hours}
				min={durationLimits.hours.min}
				max={durationLimits.hours.max}
				setInvalid={setInvalidDuration}
				onChange={(hours, addToLargerUnit=0) => {
					setInvalid(false)
					const toDuration = {
						...props.duration,
						hours,
					}
					const newDays = props.duration.days + addToLargerUnit
					if (newDays >= durationLimits.days.min && newDays <= durationLimits.days.max) {
						toDuration.days = newDays
					}
					props.changeDuration(unitsToMS(toDuration))
				}}
			/>
			<NumberPicker
				unit="minutes"
				value={props.duration.minutes}
				min={durationLimits.minutes.min}
				max={durationLimits.minutes.max}
				setInvalid={setInvalidDuration}
				onChange={(minutes, addToLargerUnit = 0) => {
					setInvalid(false)
					const toDuration = {
						...props.duration,
						minutes,
					}
					const newHours = props.duration.hours + addToLargerUnit
					if (newHours >= durationLimits.hours.min && newHours <= durationLimits.hours.max) {
						toDuration.hours = newHours
					}
					props.changeDuration(unitsToMS(toDuration))
				}}
			/> */}
			<ConfirmFieldButtons
				onRightButtonClicked={props.closeField}
				rightButtonDisabled={isInvalid}
			/>
		</React.Fragment>
	)
}

export default DurationPicker