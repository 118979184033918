import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withDatabase } from '../database/context';
import { CALENDAR_STEP_SIZES, getCurrentStartOfDay, getDayFromEvent } from '../functions/dates';
import { addTaskMethods } from '../functions/tasks';
import { isTimeout, removeGhostImage, isClassUnderCursor, getElementWithClassesUnderCursor } from '../functions/general';

const withDragNewTask = Component => {

	const Container = props => {

		// temporary task to show while being dragged
		// let sampleTempTask = addTaskMethods({
		// 	title: "",
		// 	startDate: getCurrentStartOfDay().number,
		// 	duration: CALENDAR_STEP_SIZES.DAY,
		// 	streamId: props.streamId,
		// })
		const [dragData, setDragData] = useState({})

		const RESIZE_DIRECTION = {
			LEFT: 1,
			RIGHT: 2,
		}

		// MOVE EXISTING TASKS
		const onDragStartMove = (event, taskId) => {
			event.stopPropagation()
			removeGhostImage(event)

			const firstDragDate = getDayFromEvent(event)
			if (!firstDragDate) return

			const task = props.tasks[taskId];

			const taskEdgeElement = getElementWithClassesUnderCursor(event, ["resize-task"])
			let isResize = false
			let resizeDirection;

			if (taskEdgeElement) {
				isResize = !taskEdgeElement.classList.contains("cutoff")
				if (taskEdgeElement.classList.contains("left")) {
					resizeDirection = RESIZE_DIRECTION.LEFT
				}
				if (taskEdgeElement.classList.contains("right")) {
					resizeDirection = RESIZE_DIRECTION.RIGHT
				}
			}

			setDragData({
				draggedTaskId: taskId,
				draggedTask: task,
				lastDragDate: firstDragDate,
				firstDragDate: firstDragDate,
				originalTask: task,
				tempTask: task,
				isResize,
				resizeDirection,
			})
		}

		const onDragMove = event => {
			event.stopPropagation()

			if (!dragData.tempTask) return

			const dragToDate = getDayFromEvent(event)
			if (!dragToDate) return

			if (dragToDate === dragData.lastDragDate) return

			if (!dragData.isResize) {
				setDragData({
					...dragData,
					lastDragDate: dragToDate,
					tempTask: {
						...dragData.tempTask,
						startDate: dragData.draggedTask.startDate + (dragToDate - dragData.firstDragDate),
					}
				})
			} else {
				let duration = dragData.draggedTask.duration
				let startDate = dragData.draggedTask.startDate
				switch (dragData.resizeDirection) {
					case RESIZE_DIRECTION.LEFT:
						startDate += (dragToDate - dragData.firstDragDate)
						duration -= (dragToDate - dragData.firstDragDate)
						break
					case RESIZE_DIRECTION.RIGHT:
						duration += (dragToDate - dragData.firstDragDate)
						break
				}
				if (duration < CALENDAR_STEP_SIZES.DAY) return
				setDragData({
					...dragData,
					lastDragDate: dragToDate,
					tempTask: {
						...dragData.tempTask,
						duration,
						startDate,
					}
				})
			}
		}

		const onDragEndMove = event => {
			event.stopPropagation()

			props.database.changeTask(dragData.draggedTaskId, {
				startDate: dragData.tempTask.startDate,
				duration: dragData.tempTask.duration,
			})
			setDragData({})
		}

		// NEW TASKS
		const onDragStartNew = event => {
			removeGhostImage(event)
			const startDate = getDayFromEvent(event)
			if (!startDate) return

			setDragData({
				lastDragDate: startDate,
				firstDragDate: startDate,
				tempTask: addTaskMethods({
					title: "",
					startDate: startDate,
					duration: CALENDAR_STEP_SIZES.DAY,
					streamId: props.streamId,
				})
			})
		}

		const onDragNew = event => {
			if (!dragData.tempTask) return

			const dragToDate = getDayFromEvent(event)
			if (!dragToDate) return

			if (dragToDate === dragData.lastDragDate) return
			if (dragToDate === dragData.firstDragDate) {
				setDragData({
					...dragData,
					lastDragDate: dragToDate,
					tempTask: {
						...dragData.tempTask,
						startDate: dragData.firstDragDate,
						duration: CALENDAR_STEP_SIZES.DAY,
					}
				})
			} else if (dragToDate >= dragData.firstDragDate) {
				setDragData({
					...dragData,
					lastDragDate: dragToDate,
					tempTask: {
						...dragData.tempTask,
						startDate: dragData.firstDragDate,
						duration: dragToDate - dragData.tempTask.startDate + CALENDAR_STEP_SIZES.DAY,
					}
				})
			} else {
				setDragData({
					...dragData,
					lastDragDate: dragToDate,
					tempTask: {
						...dragData.tempTask,
						startDate: dragToDate,
						duration: dragData.firstDragDate - dragToDate + CALENDAR_STEP_SIZES.DAY,
					}
				})
			}
		}

		const onDragEndNew = event => {
			props.database.newTask({
				streamId: props.streamId,
				startDate: dragData.tempTask.startDate,
				duration: dragData.tempTask.duration,
			})
			setDragData({})
		}

		const onClick = event => {
			if (isTimeout()) return

			const startDate = getDayFromEvent(event)
			if (!startDate) return

			props.database.newTask({
				streamId: props.streamId,
				startDate,
				duration: 7 * CALENDAR_STEP_SIZES.DAY
			})
		}

		const newProps = {
			onDragStart: onDragStartNew,
			onDrag: onDragNew,
			onDragEnd: onDragEndNew,
			onClick,
			toTask: {
				onDragStart: onDragStartMove,
				onDrag: onDragMove,
				onDragEnd: onDragEndMove,
				draggedTaskId: dragData.draggedTaskId,
			},
			tempTask: dragData.tempTask,
		}

		return (
			<React.Fragment>
				<Component {...props} {...newProps} />
			</React.Fragment>
		);
	}

	const mapStateToProps = state => ({
		tasks: state.tasks,
	})

	return connect(mapStateToProps)(withDatabase(Container))
}


export default withDragNewTask;