import React from 'react';
import Main from './pages/Main/Main';
import { connect } from 'react-redux';
import IfElse from './pieces/IfElse/IfElse';
import SignIn from './pages/SignIn/SignIn';

let App = props => (
	<IfElse
		if={props.isSignedIn}
		then={<Main />}
		else={<SignIn />}
	/>
)

const mapStateToProps = state => ({
	isSignedIn: state.user.signedIn,
})

export default connect(mapStateToProps)(App)
