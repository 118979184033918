import * as firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"
import { newTask, changeTask, deleteTask, newStream, changeStream, deleteStream, changeUser, replaceAllTasks, populateStreams, setSidebarFor, populateUser, restartClient, refreshTasks, refreshStreams, changeClient, showSidebarMenu } from "../redux/actions"
import { getNumDaysFromToday, CALENDAR_STEP_SIZES, dateToNum, getCurrentStartOfDay, numToOffsetDays } from "../functions/dates";
import { addTaskMethods } from "../functions/tasks";
import { focusOnTitle } from "../functions/sidebar";

const firebaseConfig = {
	apiKey: "AIzaSyBOrx51Jc5Pn9Jm1thj5qBSqu3C7kAGwos",
	authDomain: "effectivly.firebaseapp.com",
	databaseURL: "https://effectivly.firebaseio.com",
	projectId: "effectivly",
	storageBucket: "effectivly.appspot.com",
	messagingSenderId: "183463691448",
	appId: "1:183463691448:web:f156a655a39b5583"
};

class Database {
	constructor(store) {
		this.dispatch = store.dispatch
		this.store = store

		firebase.initializeApp(firebaseConfig)
		this.firestore = firebase.firestore()

		this.auth = firebase.auth()

		this.auth.onAuthStateChanged(user => {
			if (user) {
				this.userId = user.uid
				this.initializeFromFirestore()
			} else {
				this.dispatch(populateUser({signedIn: false}))
				this.dispatch(refreshTasks())
				this.dispatch(refreshStreams())
				this.dispatch(restartClient())
			}
		})
	}

	/* FIRESTORE */

	initializeFromFirestore = () => {
		this.streams().get().then(collection => {
			const streams = {}
			collection.forEach(doc => {
				streams[doc.id] = doc.data()
			})
			this.dispatch(populateStreams(streams))
		})
		this.tasks().get().then(collection => {
			const tasks = {}
			collection.forEach(doc => {
				const task = JSON.parse(JSON.stringify(doc.data()))
				// task.startDate = getNumDaysFromToday(task.startDate).number
				// task.duration *= CALENDAR_STEP_SIZES.DAY
				tasks[doc.id] = addTaskMethods(task)
			})
			this.dispatch(replaceAllTasks(tasks))
		})
		this.setAndGetUser()
	}

	setAndGetUser = async () => {
		await this.dispatch(populateUser({
			signedIn: true,
			uid: this.userId,
		}))
		this.user().get().then(async user => {
			if (user.exists) {
				const userData = user.data()
				if (userData.streamOrder) {
					await this.dispatch(changeClient({ streamOrder: userData.streamOrder }))
				}
				this.dispatch(populateUser({
					signedIn: true,
					uid: this.userId,
					...userData,
				}))
			}
		})
	}

	/* HELPERS */

	user = () => this.firestore.collection('horizon').doc(this.userId)

	streams = () => this.user(this.userId).collection('streams')

	stream = streamId => this.streams().doc(streamId)

	tasks = () => this.user(this.userId).collection('tasks')

	task = taskId => this.tasks().doc(taskId)

	// generate usage stats
	updateUsageStats = () => {
		this.user().update({last_active: new Date()}).catch(err => console.log(err.message))
		this.user().collection("usage").doc((new Date()).toISOString().substr(0, 10)).set({
			count: firebase.firestore.FieldValue.increment(1)
		}).catch(err => console.log(err.message))
	}


	// actions to database
	//   - change redux, add pending action
	//   - send change to firebase
	//   - remove pending action on result

	/* TASKS */

	newTask = async ({streamId, startDate, duration, title="", ...otherInfo}) => {
		const newTaskJSON = {
			streamId,
			duration,
			startDate,
			title,
			...otherInfo,
		}
		const ref = await this.tasks().add(newTaskJSON)
		const taskId = ref.id
		await this.dispatch(newTask(taskId, newTaskJSON))
		await this.dispatch(setSidebarFor(taskId))
		focusOnTitle()
	}

	changeTask = (taskId, newInfo, save=true) => {
		// delete temp title when firebase
		this.dispatch(changeTask(taskId, newInfo))
		delete newInfo.tempTitle
		if (save) {
			this.task(taskId).update(newInfo)
		}
	}

	deleteTask = taskId => {
		this.task(taskId).delete()
		this.dispatch(deleteTask(taskId))
	}


	/* STREAMS */

	newStream = async (title, color, streamOrder) => {
		const ref = await this.streams().add({
			title,
			color,
		})
		const streamId = ref.id//(new Date()).getTime().toString()
		await this.dispatch(newStream(streamId, {title, color}))
		const newStreamOrder = streamOrder.concat([streamId])
		await this.dispatch(changeClient({ streamOrder: newStreamOrder }))
		this.user().update({ streamOrder: newStreamOrder })
		await this.dispatch(setSidebarFor(streamId))
		focusOnTitle()
	}

	changeStream = (streamId, newInfo, save=true) => {
		this.dispatch(changeStream(streamId, newInfo))
		delete newInfo.tempTitle
		if (save) {
			this.stream(streamId).update(newInfo)
		}
	}

	deleteStream = streamId => {
		this.stream(streamId).delete()
		this.dispatch(deleteStream(streamId))
	}

	moveStream = newOrder => {
		this.dispatch(showSidebarMenu(false))
		this.user().update({ streamOrder: newOrder })
		this.dispatch(changeClient({ streamOrder: newOrder }))
	}

	/* ACCOUNTS */

	signIn = (email, password) => this.auth.signInWithEmailAndPassword(email, password)

	signOut = () => this.auth.signOut().then(() => {
		this.dispatch(populateUser({signedIn: false}))
	})

	createAccount = (email, password) => this.auth.createUserWithEmailAndPassword(email, password)

	changeUser = newInfo => {
		this.dispatch(changeUser(newInfo))
	}

	/* POPUPS */

	updatePopups = newList => {
		this.user().update({ popups: newList })
		this.dispatch(changeUser({ popups: newList }))
	}


}

export default Database