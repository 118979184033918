import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { closeSidebar, showSidebarMenu, showHeaderMenu, openSidebarField } from '../redux/actions';
import { isTimeout } from '../functions/general';
import { withDatabase } from '../database/context';

const withHandleClicks = AppComponent => {

	let Container = props => {

		useEffect(() => {
			window.addEventListener("keydown", onKeyDown)
			window.addEventListener("keyup", onKeyUp)
			window.addEventListener("click", onClick, true)
			window.addEventListener("focus", props.database.updateUsageStats)
			return () => {
				window.removeEventListener("keydown", onKeyDown)
				window.removeEventListener("keyup", onKeyUp)
				window.removeEventListener("click", onClick, true)
				window.removeEventListener("focus", props.database.updateUsageStats)
			}
		}, [props]);

		const onKeyDown = event => {
			if (event.key === "Escape") {
				closeFirstOpen()
			}
			// if (event.key === "Delete") {
			// 	if (props.sidebarOpen) {
			// 		props.closeSidebar()
			// 		if (props.isTask) {
			// 			props.database.deleteTask(props.sidebarId)
			// 		} else if (props.isStream) {
			// 			props.database.deleteStream(props.sidebarId)
			// 		}
			// 	}
			// }
			if (event.key === "Shift") {
				window.isShiftPressed = true
			}
		}

		const onKeyUp = event => {
			if (event.key === "Shift") {
				window.isShiftPressed = false
			}
		}

		const onClick = event => {
			if (isTimeout()) return
			if (props.headerMenuOpen) {
				if (!event.target.closest("#settings_menu") || event.target.closest(".material-icons")) {
					props.closeHeaderMenu()
					event.stopPropagation()
				}
				return
			}
			if (props.sidebarMenuOpen) {
				if (!event.target.closest("#sidebar_menu") || event.target.closest(".material-icons")) {
					props.closeSidebarMenu()
					event.stopPropagation()
				}
				return
			}
			if (event.target.closest("#Header")) return
			if (props.sidebarOpen) {
				if (!event.target.closest("#Sidebar, .Tasks .task, .stream.title, #settings_menu, .textbutton")) {
					props.closeSidebar()
					event.stopPropagation()
				}
				return
			}
		}

		const closeFirstOpen = () => {
			if (props.headerMenuOpen) {
				props.closeHeaderMenu()
				return
			}
			if (props.sidebarMenuOpen) {
				props.closeSidebarMenu()
				return
			}
			if (props.sidebarFieldOpen) {
				props.closeSidebarField()
				return
			}
			if (props.sidebarOpen) {
				props.closeSidebar()
				return
			}
		}

		return (
			<React.Fragment>
				<AppComponent {...props} />
			</React.Fragment>
		);
	}

	const mapStateToProps = state => ({
		sidebarOpen: state.client.sidebar.id !== undefined,
		sidebarFieldOpen: state.client.sidebar.field,
		sidebarMenuOpen: state.client.sidebar.menuOpen,
		headerMenuOpen: state.client.header.menuOpen,
		sidebarId: state.client.sidebar.id,
		isTask: state.tasks.hasOwnProperty(state.client.sidebar.id),
		isStream: state.streams.hasOwnProperty(state.client.sidebar.id),
	})

	const mapDispatchToProps = dispatch => ({
		closeSidebar: () => dispatch(closeSidebar()),
		closeSidebarField: () => dispatch(openSidebarField()),
		closeSidebarMenu: () => dispatch(showSidebarMenu(false)),
		closeHeaderMenu: () => dispatch(showHeaderMenu(false)),
	})

	Container = connect(mapStateToProps, mapDispatchToProps)(withDatabase(Container))

	return Container

}

export default withHandleClicks;