import React, { useState } from 'react'
import './DatetimePicker.css'
import { numToDate, getNumDaysFromDate, isSameDay, dateToNum } from '../../../../functions/dates'
import dateFormat from 'dateformat'
import { ConfirmFieldButtons } from '../../Parts/Parts'

const DatetimePicker = props => {

	const getFirstDayOnCalendar = curDate => {
		const date = new Date(curDate)
		date.setDate(1)
		date.setHours(0, 0, 0, 0)
		if (date.getDay() !== 0) {
			date.setDate(-date.getDay() + 1)
		}
		return date
	}

	const getFirstDayOfMonth = curDate => {
		const date = new Date(curDate)
		date.setDate(1)
		date.setHours(0, 0, 0, 0)
		return date
	}

	const [state, setState] = useState({
		firstDayOfMonth: getFirstDayOfMonth(numToDate(props.currentDate)),
		isInvalid: false,
	})

	const nextMonth = () => {
		const date = new Date(state.firstDayOfMonth)
		date.setMonth(state.firstDayOfMonth.getMonth() + 1)
		setState({
			...state,
			firstDayOfMonth: date,
		})
	}

	const prevMonth = () => {
		const date = new Date(state.firstDayOfMonth)
		date.setMonth(state.firstDayOfMonth.getMonth() - 1)
		setState({
			...state,
			firstDayOfMonth: date,
		})
	}

	const firstDayOnCalendar = getFirstDayOnCalendar(state.firstDayOfMonth)

	const datesInMonth = []
	for (
		let curDate = firstDayOnCalendar;
		curDate < state.firstDayOfMonth ||
			curDate.getMonth() === state.firstDayOfMonth.getMonth() ||
			curDate.getDay() !== 0;
		curDate = getNumDaysFromDate(curDate, 1).date
	) {
		datesInMonth.push(curDate)
	}

	return (
		<React.Fragment>
			<div className="datetime-picker">
				<i
					className="arrow left material-icons button"
					onClick={prevMonth}
				>chevron_left</i>
				<div className="month medium">{dateFormat(state.firstDayOfMonth, "mmmm yyyy")}</div>
				<i
					className="arrow right material-icons button"
					onClick={nextMonth}
				>chevron_right</i>
				{['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, i) => (
					<div
						className="weekday medium"
						key={i}
					>{day}</div>
				))}
				{datesInMonth.map(date => (
					<div
						key={date.getTime()}
						className={
							"day button" +
							(date.getMonth() !== state.firstDayOfMonth.getMonth() ? " othermonth" : "") +
							(isSameDay(date, numToDate(props.currentDate)) ? " selected" : "") +
							(date.getTime() < props.disableBefore ? " disabled" : "")
						}
						onClick={() => props.onSelect(dateToNum(date))}
					>{date.getDate()}</div>
				))}
			</div>
			<div style={{ height: "8px" }} />
			{/* <TimePicker
				currentDate={props.currentDate}
				onChange={date => {
					props.onSelect(date)
					setState({
						...state,
						isInvalid: false
					})
				}}
				setInvalid={() => setState({
					...state,
					isInvalid: true,
				})}
			/> */}
			<ConfirmFieldButtons
				onRightButtonClicked={props.closeField}
				rightButtonDisabled={state.isInvalid}
			/>
		</React.Fragment>
	)
}

export default DatetimePicker