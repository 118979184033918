import React from 'react';
import './Popup.css';

const Popup = props => {

	const WrapBackground = ownProps => {
		if (props.noBackground) {
			return (
				<React.Fragment>{ownProps.children}</React.Fragment>
			)
		} else {
			return (
				<div
					className={"popup container" + (props.noBackground ? "" : " dark")}
					id={props.id}
					onClick={props.noBackground ?
						() => { } :
						props.outsideClick ?
							props.outsideClick :
							props.onExit
					}
				>
					{ownProps.children}
				</div>
			)
		}
	}

	const style = {}
	if (props.maxWidth) style.maxWidth = `${props.maxWidth}px`

	return (
		<WrapBackground>
			<div
				className={
					"Popup"
					+ (props.top ? " top" : "")
					+ (props.right ? " right" : "")
					+ (props.bottom ? " bottom" : "")
					+ (props.left ? " left" : "")
				}
				onClick={e => e.stopPropagation()}
			>
				<div className="popup content" style={style}>
					<div className="popup title">{props.title}</div>
					<div className="popup text">{props.content}</div>
					<div className="popup actions">
						{props.leftAction &&
							<div
								className="popup action textbutton "
								onClick={props.leftAction[1]}
							>{props.leftAction[0]}</div>
						}
						{props.rightAction &&
							<div
								className="popup action textbutton default"
								onClick={props.rightAction[1]}
							>{props.rightAction[0]}</div>
						}
					</div>
				</div>
			</div>
		</WrapBackground>
	);
}

export default Popup;
