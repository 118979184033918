import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withDatabase } from '../../database/context';
import { changeUser } from '../../redux/actions';
import Popup from './Popup';

const Popups = props => {

	const onExit = () => {
		const popups = props.popups.slice()
		popups.shift()
		props.database.updatePopups(popups)
	}

	const onNext = () => {
		const popups = props.popups.slice()
		if (!popup.nextPopup || !popupInfo.hasOwnProperty(popup.nextPopup)) {
			onExit()
			return
		}
		popups[0] = popup.nextPopup
		props.localChangePopups(popups)
	}

	const onKeyDown = event => {
		if (!props.popups || props.popups.length === 0) return
		if (event.key === "Enter") {
			onNext()
			event.stopPropagation()
		}
	}

	useEffect(() => {
		window.addEventListener("keydown", onKeyDown);
		return () => {
			window.removeEventListener("keydown", onKeyDown);
		}
	}, [props.popups, props.database, props.localChangePopups]);

	const popupInfo = {
		"intro": {
			title: "Welcome to Effectivly!",
			content: <div>This is your new home for all of your project and assignment deadlines. Some of your deadlines are already here for you, and the rest you can easily add on your own. I hope this helps you keep track of all of the things going on in your busy life! <br /><br />

			By the way, this website is only supported on Google Chrome on desktop, so while you might be able to view your deadlines on a phone, you won't be able to use all of the features. <br /><br />

			Let's start with a quick tour...</div>,
			leftAction: ["No thanks", onExit],
			rightAction: ["Get Started!", onNext],
			outsideClick: onNext,
			nextPopup: "tasks-1",
		},
		"tasks-1": {
			title: "Tasks",
			content: "Your tasks are shown on the calendar above. A task can be an assignment deadline, project milestone, or anything you want to get done in a specific time range.",
			right: true,
			bottom: true,
			noBackground: true,
			leftAction: ["Skip", onExit],
			rightAction: ["Next", onNext],
			nextPopup: "tasks-2",
		},
		"tasks-2": {
			title: "Tasks",
			content: "The first day of a task's date range is the day you can start working on it (i.e. when it's assigned). A task in the future is brightly colored, but once you can start working on it, it'll turn dark.",
			right: true,
			bottom: true,
			noBackground: true,
			leftAction: ["Skip", onExit],
			rightAction: ["Next", onNext],
			nextPopup: "tasks-3",
		},
		"tasks-3": {
			title: "Tasks",
			content: "Now, go ahead and click on a task. In the details panel, you can mark it as complete, and it will turn grey! You can also delete it by clicking on the three dots in the top right of the panel. Click anywhere to close the panel.",
			left: true,
			bottom: true,
			noBackground: true,
			leftAction: ["Skip", onExit],
			rightAction: ["Next", onNext],
			nextPopup: "tasks-4",
		},
		"tasks-4": {
			title: "Tasks",
			content: "Drag tasks to move them around. Drag in any empty space on the calendar to create a new task.",
			left: true,
			bottom: true,
			noBackground: true,
			leftAction: ["Skip", onExit],
			rightAction: ["Next", onNext],
			nextPopup: "nav-1",
		},
		"nav-1": {
			title: "Keyboard Navigation",
			content: "To move around in the calendar, use your keyboard's arrow keys! Left / right to move across time, and up / down arrow keys to zoom in or out! You can also use the navigation buttons at the top of the site. Now, try pressing shift and an arrow key at the same time...",
			left: true,
			bottom: true,
			noBackground: true,
			leftAction: ["Skip", onExit],
			rightAction: ["Next", onNext],
			nextPopup: "streams-1",
		},
		"streams-1": {
			title: "Streams",
			content: "Over on the left is a list of streams. A stream is a class, club, job, or any other part of your life that has deadlines. You can add a new one at the bottom left.",
			right: true,
			bottom: true,
			noBackground: true,
			leftAction: ["Skip", onExit],
			rightAction: ["Next", onNext],
			nextPopup: "streams-2",
		},
		"streams-2": {
			title: "Editing a stream",
			content: "To edit a stream, click on its name. In the detail panel, you can change its name or color. Click on the three dots in the top right to move it up or down in your list or delete it.",
			right: true,
			bottom: true,
			noBackground: true,
			leftAction: ["Skip", onExit],
			rightAction: ["Next", onNext],
			nextPopup: "tips",
		},
		"tips": {
			title: "You're ready to go!",
			content: <div>To get the most out of this tool, you need to commit to inserting, updating, and checking off your tasks! Here are a couple tips that might be helpful: <br />
				<ul>
					<li>If a project or essay has a far-out deadline, make your own smaller deadlines along the way</li>
					<li>Add deadlines for errands you need to run</li>
					<li>Try to start working on every assignment as soon as it's assigned and becomes dark</li>
				</ul>
			Your mission, should you choose to accept it, is to complete all of your current tasks. This will make all of the dark tasks grey! I believe in you! <br /> <br />

			If you ever want to come back to this tour, it'll be in the settings in the top right. If you have any questions about any part of the site, let me know!</div>,
			maxWidth: 500,
			rightAction: ["Let's go!", onExit],
		},
		"feedback": {
			title: "Thanks for using Effectivly!",
			content: "Remember, if you have any feedback about the website, let me know either by clicking the button in the top right (where you can send anonymous feedback), or just send me a message.",
			rightAction: ["Got it", onExit],
		},
		"desc": {
			title: "[new] Task and stream descriptions",
			content: "All tasks and streams now have a description field, where you can add any details you need. You can also paste links into a description and they'll be clickable!",
			rightAction: ["Awesome!", onExit]
		}
	}


	if (!props.popups || props.popups.length === 0) return <React.Fragment />

	const popupId = props.popups[0]
	if (!popupInfo.hasOwnProperty(popupId)) return <React.Fragment />

	const popup = popupInfo[popupId]

	return <Popup
		onExit={onExit}
		{...popup}
	/>;
}

const mapStateToProps = state => ({
	popups: state.user.popups,
})

const mapDispatchToProps = dispatch => ({
	localChangePopups: (newList) => dispatch(changeUser({ popups: newList }))
})

export default connect(mapStateToProps, mapDispatchToProps)(withDatabase(Popups));
