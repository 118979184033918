import { STREAM } from "../actions"

const SAMPLE_STREAMS = {}
// {
// 	0: { title: "CS 168", color: "blue" },
// 	1: { title: "CS 181", color: "green" },
// 	2: { title: "PSYCH 70", color: "red" },
// 	3: { title: "CS 191", color: "gold" },
// }

const newStreamReducer = (state, action) => ({
	...state,
	[action.streamId]: action.streamInfo
})

const changeStreamReducer = (state, action) => ({
	...state,
	[action.streamId]: {
		...state[action.streamId],
		...action.newInfo,
	}
})

const deleteStreamReducer = (state, action) => {
	const {
		[action.streamId]: _,
		...newState
	} = state
	return newState
}

// TODO: set init to:  = {}
const streams = (state = SAMPLE_STREAMS, action) => {
	switch (action.type) {
		case STREAM.NEW:
			return newStreamReducer(state, action)
		case STREAM.CHANGE:
			return changeStreamReducer(state, action)
		case STREAM.DELETE:
			return deleteStreamReducer(state, action)
		case STREAM.POPULATE:
			return action.streams
		default:
			return state
	}
}

export default streams