import React from 'react';
import './Main.css';
import '../../constants/global.css';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import Calendar from '../../components/Calendar/Calendar';
import withCalendar from '../../wrappers/withCalendar';
import { compose } from 'redux';
import withFirebase from '../../wrappers/withFirebase';
import Streams from '../../components/Streams/Streams';
import withHandleClicks from '../../wrappers/withHandleClicks';
import Popups from '../../pieces/Popup/Popups';
import { withDatabase } from '../../database/context';

let Main = props => {
	if (window.location.pathname === "/signout") {
		props.database.signOut().then(() => {
			window.location.assign("/")
		}).catch(() => {
			window.location.assign("/")
		})
		return <React.Fragment />
	}
	return (
		<div id="App" className="full-height">

			{/* POPUPS */}
			<Popups />

			{/* HEADER */}
			<Header />
			<div id="Content">
				<div id="Main" >
					<div id="Overlay">
						<div style={{ height: props.calendar.titleHeight + "px" }}></div>

						{/* STREAMS */}
						<Streams />
					</div>

					{/* CALENDAR */}
					<div id="Calendar">
						<Calendar />
					</div>
				</div>

				{/* SIDEBAR */}
				<Sidebar />
			</div>
		</div>
	)
}

export default compose(
	withCalendar,
	withFirebase,
	withHandleClicks,
	withDatabase
)(Main);
