import React, { useEffect } from 'react';

const withFirebase = Component => {
	let Container = props => {

		useEffect(() => {
			// create listeners (onSnapshot) for:
			// 	  update tasks
			//    update categories
			//    update users
			// each listener updates redux
			// console.log("initial populate redux")
		}, []);

		return (
			<React.Fragment>
				<Component {...props} />
			</React.Fragment>
		);
	}



	return Container;
}

export default withFirebase;