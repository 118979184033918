import React, { useRef } from 'react'
import { connect } from 'react-redux'
import { Heading, FormGroup, ButtonWithColor, Description, ButtonField } from '../Parts/Parts'
import { STREAM_COLORS } from '../../Streams/Streams'
import { getTasksInStream } from '../../Tasks/Tasks'
import { setSidebarFor, openSidebarField } from '../../../redux/actions'
import { withDatabase } from '../../../database/context'
import IfElse from '../../../pieces/IfElse/IfElse'
import { addLinksToText } from '../../../functions/general'

const StreamContent = props => {

	const sortTasks = () => {
		// sort incomplete first
		// return Object.keys(props.tasks).sort((a, b) => props.tasks[a].complete ? (props.tasks[b].complete ? 0 : 1) : (props.tasks[b].complete ? -1 : 0))
		// remove incompletes
		return Object.keys(props.tasks).sort((a, b) => {
			const startDateA = props.tasks[a].startDate
			const startDateB = props.tasks[b].startDate
			if (startDateA < startDateB) return -1
			if (startDateA > startDateB) return 1
			return 0
		}).filter(taskId => !props.tasks[taskId].complete)
	}

	const FIELDS = {
		COLORS: "COLORS",
		DESCRIPTION: "DESCRIPTION",
	}

	const descRef = useRef(null)

	return (
		<React.Fragment>
			<FormGroup>
				<Heading value="Description" />
				<IfElse
					if={props.fieldInFocus !== FIELDS.DESCRIPTION}
					then={
						<Description
							placeholder="Additional stream details, links, etc."
							editMode={false}
							text={addLinksToText(props.stream.description)}
							onClick={() => props.openSidebarField(FIELDS.DESCRIPTION)}
						/>
					}
					else={
						<Description
							placeholder="Additional stream details, links, etc."
							editMode={true}
							text={props.stream.description}
							exit={() => {
								props.database.changeStream(props.streamId, { description: descRef.current.value })
								props.openSidebarField()
							}}
							refVar={descRef}
						/>
					}
				/>
			</FormGroup>

			<FormGroup>
				<Heading value="Color" />
				{props.fieldInFocus !== FIELDS.COLORS &&
					<ButtonWithColor
						color={STREAM_COLORS[props.stream.color].dark}
						onClick={() => props.openSidebarField(FIELDS.COLORS)}
					>
						{props.stream.color.charAt(0).toUpperCase() + props.stream.color.slice(1)}
					</ButtonWithColor>
				}
				{props.fieldInFocus === FIELDS.COLORS &&
					<div className="basicOptions">
						{[props.stream.color, ...Object.keys(STREAM_COLORS).filter(c => c !== props.stream.color)].map(color => (
							<ButtonWithColor
								key={color}
								color={STREAM_COLORS[color].dark}
								onClick={() => {
									props.openSidebarField()
									if (color === props.stream.color) return
									props.database.changeStream(props.streamId, {color: color})
								}}
								selected={color === props.stream.color}
							>
								{color.charAt(0).toUpperCase() + color.slice(1)}
							</ButtonWithColor>
						))}
					</div>
				}
			</FormGroup>

			{sortTasks().length > 0 &&
				<FormGroup>
					<Heading value="Incomplete Tasks" />
					{sortTasks().map(taskId => (
						<ButtonField key={taskId} onClick={() => props.setSidebarFor(taskId)}>
							{!props.tasks[taskId].complete && props.tasks[taskId].title}
							{props.tasks[taskId].complete && <i>{props.tasks[taskId].title}</i>}
						</ButtonField>
					))}
				</FormGroup>
			}
		</React.Fragment>
	)
}

const mapStateToProps = state => {

	const streamId = state.client.sidebar.id
	const stream = state.streams[streamId]

	return {
		streamId: streamId,
		stream: stream,
		tasks: getTasksInStream(streamId, state.tasks),
		fieldInFocus: state.client.sidebar.field,
	}
}

const mapDispatchToProps = dispatch => ({
	setSidebarFor: id => dispatch(setSidebarFor(id)),
	openSidebarField: field => dispatch(openSidebarField(field)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withDatabase(StreamContent))
