import React, { useRef, useEffect } from 'react'
import './Streams.css'
import { setSidebarFor, changeClient } from '../../redux/actions'
import { connect } from 'react-redux'
import Tasks from '../Tasks/Tasks'
import Icon from '../../pieces/Icon/Icon'
import { withDatabase } from '../../database/context'
import { runAtMostEvery } from '../../functions/general'
import { focusOnTitle } from '../../functions/sidebar'
import { sortByStreamOrder } from '../../functions/streams'

export const STREAM_COLORS = {
	"pink": { dark: "#d989bd", light: "#ffebf8" },
	"red": { dark: "#e57f7f", light: "#ffe4e4" },
	"orange": { dark: "#df9f4f", light: "#ffebd4" },
	"gold": { dark: "#cfbb64", light: "#faf5de" },
	"green": { dark: "#7dbe97", light: "#defceb" },
	"turquoise": { dark: "#4eafaa", light: "#dcf8f3" },
	"blue": { dark: "#6c89bc", light: "#eaedfc" },
	"purple": { dark: "#9580c9", light: "#eee7fc" },
	"brown": { dark: "#aa8b76", light: "#faece1" },
	// "gray": { dark: "#aaaaaa", light: "#f2f2f2" },
}

export const COMPLETED_COLOR = "rgb(233, 233, 233)"//"#f0f0f0"

let Streams = props => {

	// const alphabetical = (a, b) => {
	// 	const aIndex = props.streams[a].index
	// 	const bIndex = props.streams[b].index
	// 	if (aIndex > bIndex) {
	// 		return 1
	// 	}
	// 	if (aIndex < bIndex) {
	// 		return -1
	// 	}
	// 	return 0
	// }

	let container = useRef(null)

	const updateIfScrollbar = () => {
		if (container.current.scrollHeight > container.current.clientHeight) {
			if (!props.isScrollbar) {
				props.setScrollbar(true)
			}
		} else {
			if (props.isScrollbar) {
				props.setScrollbar(false)
			}
		}
	}

	useEffect(() => {
		updateIfScrollbar()
		window.addEventListener("resize", updateIfScrollbar)
		return () => {
			window.removeEventListener("resize", updateIfScrollbar)
		}
	}, [props.streams, props.tasks, props.isScrollbar])

	return (
		<div id="Streams" ref={container}>
			{sortByStreamOrder(Object.keys(props.streams), props.streamOrder).map((streamId, i) => {
				const stream = props.streams[streamId]
				const isSelected = props.sidebarId === streamId

				const onClick = event => {
					event.stopPropagation()
					runAtMostEvery(() => props.streamClicked(streamId))
				}

				const onDoubleClick = event => {
					focusOnTitle()
				}

				// EACH STREAM
				return (
					<div
						className={
							"Stream" +
							(i === Object.keys(props.streams).length - 1 ? " stream-spacer" : "")
						}
						key={streamId}
						style={{
							backgroundColor: isSelected ? (STREAM_COLORS[stream.color].light + "a0") : ""
						}}
					>

						{/* TITLE AREA */}
						<div
							className="stream title"
							onClick={onClick}
							onDoubleClick={onDoubleClick}
						>

							{/* COLLAPSE BUTTON? */}
							{/* <div className="stream icon-collapse">
								<Icon>expand_more</Icon>
							</div> */}

							{/* TITLE TEXT */}
							<div className={
								"stream text" +
								(isSelected ? " bold" : " medium") +
								((stream.title || stream.tempTitle) ? "" : " unnamed")
							}>
								{stream.tempTitle || stream.title || "Unnamed Stream"}
							</div>
						</div>

						{/* TASKS */}
						<div className="stream task-container">
							<Tasks streamId={streamId} />
						</div>
					</div>
					)
			})}

			{/* <div className="stream-spacer"></div> */}

			{/* NEW STREAM BUTTON */}
			<div className="Stream">
				<div
					className="stream title new-stream"
					onClick={() => {
						const streamOrder = sortByStreamOrder(Object.keys(props.streams), props.streamOrder)
						props.database.newStream("", Object.keys(STREAM_COLORS)[Math.floor(Math.random() * Object.keys(STREAM_COLORS).length)], streamOrder)
					}}
				>
					<div className="stream text">
						<div className="stream icon">
							<i className="material-icons stream new-icon">add</i>
						</div>
						New Stream
					</div>
				</div>
				<div className="stream task-container" />
			</div>
		</div>
	);
}

const mapStateToProps = state => ({
	streams: state.streams,
	tasks: state.tasks,
	sidebarId: state.client.sidebar.id,
	isScrollbar: state.client.streams.isScrollbar,
	streamOrder: state.client.streamOrder,
})

const mapDispatchToProps = dispatch => ({
	streamClicked: streamId => dispatch(setSidebarFor(streamId)),
	setScrollbar: ifScrollbar => dispatch(changeClient({
		streams: {
			isScrollbar: ifScrollbar,
		}
	}))
})

Streams = connect(mapStateToProps, mapDispatchToProps)(withDatabase(Streams))

export default Streams
