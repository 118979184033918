import React, { useRef, useEffect } from 'react'
import './Sidebar.css'
import Icon from '../../pieces/Icon/Icon'
import Menu from '../../pieces/Menu/Menu'
import { closeSidebar, showSidebarMenu } from '../../redux/actions'
import { connect } from 'react-redux'
import TaskContent from './TaskContent/TaskContent'
import StreamContent from './StreamContent/StreamContent'
import { EditTitle } from './Parts/Parts'
import { withDatabase } from '../../database/context'
import usePrevious from '../../functions/usePrevious'
import { sortByStreamOrder } from '../../functions/streams'

const SIDEBAR_TYPE = {
	TASK: 'TASK',
	STREAM: 'STREAM',
}

const mapStateToProps = state => {
	const sidebarState = {
		...state.client.sidebar,
	}
	const id = state.client.sidebar.id
	if (id) {
		if (state.streams.hasOwnProperty(id)) {
			sidebarState.title = state.streams[id].title
			sidebarState.type = SIDEBAR_TYPE.STREAM
			sidebarState.streamOrder = state.client.streamOrder
			sidebarState.streams = state.streams
		} else if(state.tasks.hasOwnProperty(id)) {
			// if task with non-existent stream, don't open sidebar
			if (!state.streams.hasOwnProperty(state.tasks[id].streamId)) {
				return {id: undefined, menuOpen: false}
			}
			sidebarState.title = state.tasks[id].title
			sidebarState.type = SIDEBAR_TYPE.TASK
			sidebarState.task = state.tasks[id]
		}
	}
	return sidebarState
}

const mapDispatchToProps = dispatch => {
	return {
		close: () => dispatch(closeSidebar()),
		showMenu: (menuOpen=true) => dispatch(showSidebarMenu(menuOpen)),
	}
}

let Sidebar = props => {

	// useEffect(() => {
	// 	const unloadAlert = event => {
	// 		event.preventDefault()
	// 		event.returnValue = ''
	// 	}
	// 	window.addEventListener("beforeunload", unloadAlert)
	// 	return () => {
	// 		window.removeEventListener("beforeunload", unloadAlert)
	// 	}
	// }, [])

	const titleRef = useRef(null)

	const prevInfo = usePrevious({
		id: props.id,
		title: props.title,
		type: props.type,
	})

	useEffect(() => {
		if (!prevInfo) return
		if (props.id !== prevInfo.id && prevInfo.id) {
			if (!prevInfo.title) {
				switch (prevInfo.type) {
					case SIDEBAR_TYPE.TASK:
						props.database.deleteTask(prevInfo.id)
						break
					// case SIDEBAR_TYPE.STREAM:
					// 	props.database.deleteStream(prevInfo.id)
					// 	break
				}
			}
		}
	}, [props.id])

	const onTitleBlur = () => {
		const newTitle = titleRef.current.innerText
		if (!newTitle) {
			titleRef.current.innerText = props.title || ""
			return
		}
		if (newTitle !== props.title) {
			switch (props.type) {
				case SIDEBAR_TYPE.TASK:
					props.database.changeTask(props.id, { title: newTitle })
					break
				case SIDEBAR_TYPE.STREAM:
					props.database.changeStream(props.id, { title: newTitle })
					break
			}
		}
	}

	const onKeyUp = () => onTitleChange()

	const onTitleChange = () => {
		const newTitle = titleRef.current.innerText
		if (newTitle !== props.title) {
			switch (props.type) {
				case SIDEBAR_TYPE.TASK:
					props.database.changeTask(props.id, { tempTitle: newTitle }, false)
					break
				case SIDEBAR_TYPE.STREAM:
					props.database.changeStream(props.id, { tempTitle: newTitle }, false)
					break
			}
		}
	}

	const discardChanges = () => {
		titleRef.current.innerText = props.title || ""
		switch (props.type) {
			case SIDEBAR_TYPE.TASK:
				props.database.changeTask(props.id, { tempTitle: undefined }, false)
				break
			case SIDEBAR_TYPE.STREAM:
				props.database.changeStream(props.id, { tempTitle: undefined }, false)
				break
		}
	}

	if (!props.id) {
		return <div></div>
	}

	let leftAction;
	let rightAction;
	let overflowActions = []
	let content = <div></div>
	let titlePlaceholder = ""
	switch(props.type) {
		case SIDEBAR_TYPE.TASK:
			if (!props.task.complete) {
				rightAction = {
					text: "Mark complete",
					onClick: () => {
						props.database.changeTask(props.id, {complete: true})
						props.close()
					}
				}
			} else {
				rightAction = {text: "Mark incomplete", onClick: () => props.database.changeTask(props.id, {complete: false})}
			}
			overflowActions = [
				["Duplicate",
					() => {
						props.close()
						props.database.newTask(props.task.toDB())
					}],
				["Delete",
					() => {
						props.close()
						props.database.deleteTask(props.id)
					}],
			]
			content = <TaskContent />
			titlePlaceholder = "Task name"
			break
		case SIDEBAR_TYPE.STREAM:
			const moveUp = ["Move up",
				() => {
					const order = sortByStreamOrder(Object.keys(props.streams), props.streamOrder)
					const curIndex = order.indexOf(props.id)
					if (curIndex === -1 || curIndex === 0) return
					const beforeElems = order.slice(0, curIndex - 1)
					const switchItem = order[curIndex - 1]
					const afterElems = order.slice(curIndex + 1, order.length)
					const newOrder = beforeElems.concat([props.id, switchItem]).concat(afterElems)
					props.database.moveStream(newOrder)
				}]
			const moveDown = ["Move down",
				() => {
					const order = sortByStreamOrder(Object.keys(props.streams), props.streamOrder)
					const curIndex = order.indexOf(props.id)
					if (curIndex === -1 || curIndex === order.length - 1) return
					const beforeElems = order.slice(0, curIndex)
					const switchItem = order[curIndex + 1]
					const afterElems = order.slice(curIndex + 2, order.length)
					const newOrder = beforeElems.concat([switchItem, props.id]).concat(afterElems)
					props.database.moveStream(newOrder)
				}]
			const order = sortByStreamOrder(Object.keys(props.streams), props.streamOrder)
			if (order[0] !== props.id) {
				overflowActions.push(moveUp)
			}
			if (order[order.length - 1] !== props.id) {
				overflowActions.push(moveDown)
			}
			overflowActions.push(
				["Delete",
					() => {
						props.close()
						props.database.deleteStream(props.id)
					}]
			)
			content = <StreamContent />
			titlePlaceholder = "Stream name"
			break
	}

	return (
		<div id="Sidebar">
			<div className="sidebar title">
				<div className="sidebar title-icon">
					<Icon onClick={props.close}>close</Icon>
				</div>
				{/* <div className="sidebar title-text bold">{props.title}</div> */}
				<EditTitle
					placeholder={titlePlaceholder}
					value={props.title}
					refVar={titleRef}
					onBlur={onTitleBlur}
					onKeyUp={onKeyUp}
					discardChanges={discardChanges}
				/>
				<div className="sidebar title-icon">
					<Menu id="sidebar_menu" show={props.menuOpen} actions={overflowActions} >
						<Icon onClick={() => props.showMenu(!props.menuOpen)} hover={props.menuOpen}>more_vert</Icon>
					</Menu>
				</div>
			</div>
			<div className="sidebar content">
				{content}
			</div>
			{(leftAction || rightAction) &&
				<div className="actions medium">
					{leftAction &&
						<div
							className="action"
							onClick={leftAction.onClick}
						>{leftAction.text.toUpperCase()}</div>
					}
					<div className="spacer" />
					{rightAction &&
						<div
							className="action"
							onClick={rightAction.onClick}
						>{rightAction.text.toUpperCase()}</div>
					}
				</div>
			}
		</div>
	);
}

Sidebar = connect(mapStateToProps, mapDispatchToProps)(withDatabase(Sidebar))

export default Sidebar
