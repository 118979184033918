
export const focusOnTitle = () => {
	const fields = document.getElementsByClassName("sidebarParts title textfield")
	if (fields.length > 0) {
		fields[0].focus()
		document.execCommand("selectAll")
	}
}

export const VALID_STRING = {
	validNum: true,
	inRange: true,
}

export const isStringValidNumber = (num, min=-Infinity, max=Infinity) => {
	if (!num) {
		return {
			validNum: false,
			inRange: true,
		}
	}
	if (isNaN(num)) {
			return {
				validNum: false,
				inRange: false,
			}
	}
	num = parseInt(num)
	if (num < min || num > max) {
			return {
				validNum: true,
				inRange: false,
			}
	} else {
		return {
			validNum: true,
			inRange: true,
			number: num,
		}
	}
}

export const stopPropagateButEscape = event => {
	if (event.key === "Escape") return
	event.stopPropagation()
}