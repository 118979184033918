import { USER } from "../actions"

const INIT_USER = {
	signedIn: false,
}

const user = (state = INIT_USER, action) => {
	switch (action.type) {
		case USER.CHANGE:
			return {
				...state,
				...action.newInfo,
			}
		case USER.POPULATE:
			return action.user
		default:
			return state
	}
}

export default user