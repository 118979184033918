import React, { useState, useEffect, useRef } from 'react'
import './Calendar.css'
import { connect } from 'react-redux'
import { setCalendar } from '../../redux/actions'
import { propsToDateArray, dateToNum, getCurrentDate, isStartOfWeek, getNumDaysFromDate } from '../../functions/dates'
import dateFormat from 'dateformat'

let Calendar = props => {

	// handle changing text and size upon resize
	let container = useRef(null)

	const ONE_ROW = 1
	const TWO_ROWS = 2
	const [titleState, setTitleState] = useState(undefined)

	const updateContainerSize = () => {
		if (container.current === null) {
			setTitleState(undefined)
			return
		}

		const width = container.current.offsetWidth
		if ((width / props.numCols) < 90) {
			if (titleState !== TWO_ROWS) {
				setTitleState(TWO_ROWS)
			}
		} else if(titleState !== ONE_ROW) {
			setTitleState(ONE_ROW)
		}
	}

	useEffect(() => {
		updateContainerSize()
		window.addEventListener("resize", updateContainerSize)
		return () => {
			window.removeEventListener("resize", updateContainerSize)
		}
	}, [props.sidebarShown, titleState, props.numCols])

	useEffect(() => {
		switch(titleState) {
			case TWO_ROWS:
				props.setCalendarTitleHeight(45)
				break
			case ONE_ROW:
				props.setCalendarTitleHeight(30)
				break
		}
	}, [titleState])

	// get text to show for each date
	const getDate = date => {
		if (titleState === TWO_ROWS) {
			return (
				<div className="calendar label medium" style={{ height: "45px" }}>
					{dateFormat(date, "ddd")[0]}
					<br />
					{dateFormat(date, "d")}
				</div>
			)
		} else {// if (titleState === ONE_ROW) {
			return (
				<div className="calendar label medium" style={{ height: "30px" }}>
					{dateFormat(date, "ddd d")}
				</div>
			)
		}
	}

	const dates = propsToDateArray(props)
	return (
		<div className="Calendar" ref={container}>
			{dates.map((date, i) => {
				const isCurrent = (getCurrentDate().number >= dateToNum(date) && getCurrentDate().number <= getNumDaysFromDate(date, 1).number)
				const isPast = getCurrentDate().number > getNumDaysFromDate(date, 1).number
				return (
					<div
						key={dateToNum(date)}
						className={
							"calendar day" +
							(i === 0 ? " first" : "") +
							(isCurrent ? " current bold" : "") +
							(isPast ? " past" : "") +
							(isStartOfWeek(date) ? " start-of-week" : "")
						}
						id={dateToNum(date)}
					>
						{getDate(date)}
					</div>
				)
			})}
			<div className="space-for-scrollbar" style={{ flex: `0 0 ${props.isScrollbar ? 8 : 0}px` }} />
		</div>
	)
}

const mapStateToProps = state => {
	return ({
		sidebarShown: state.client.sidebar.id !== undefined,
		...state.client.calendar,
		isScrollbar: state.client.streams.isScrollbar,
	})
}

const mapDispatchToProps = dispatch => ({
	setCalendarTitleHeight: height => dispatch(setCalendar({ titleHeight: height }))
})

Calendar = connect(mapStateToProps, mapDispatchToProps)(Calendar)

export default Calendar
