import { combineReducers } from "redux"
import streams from "./reducers/streamReducer"
import tasks from "./reducers/taskReducer"
import client from "./reducers/clientReducers"
import user from "./reducers/userReducers"

const combinedReducers = combineReducers({
	client,
	user,
	streams,
	tasks,
})

const mainReducer = (state, action) => {
	const finalState = combinedReducers(state, action)
	// console.log(action)
	// console.log(finalState)
	return finalState
}

export default mainReducer