import React from 'react'

export const runAtMostEvery = (run, milliseconds=200) => {
	if (!window.timeout) {
		run()
		window.timeout = window.setTimeout(() => {
			window.timeout = undefined
		}, milliseconds)
	}
}

export const isTimeout = () => window.timeout !== undefined

export const removeGhostImage = event => {
	var img = new Image()
	img.src = ''
	event.dataTransfer.setDragImage(img, 0, 0)
}

export const getElementWithClassesUnderCursor = (event, classes) => {
	const elementsUnderCursor = document.elementsFromPoint(event.clientX, event.clientY)
	for (let element of elementsUnderCursor) {
		if (!classes.map(className => element.classList.contains(className)).includes(false)) {
			return element
		}
	}
	return undefined
}

export const addLinksToText = text => {
	if (!text || text.length === 0) return ""
	const urlRegex = /(https?:\/\/[^\s]+)/g
	const parts = text.split(urlRegex)
	let urlText = parts.map(part => {
		if (part.match(urlRegex)) {
			return <a key={part} href={part} target="_blank">{part}</a>
		} else {
			return part
		}
	})
	return urlText
}