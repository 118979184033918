import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import mainReducer from './redux/reducers';
import DatabaseContext from './database/context';
import Database from './database/database';

const store = createStore(mainReducer);

ReactDOM.render(
	<DatabaseContext.Provider value={new Database(store)}>
		<Provider store={store}>
			<App />
		</Provider>
	</DatabaseContext.Provider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
