// Interface actions
export const CLIENT = {
	CHANGE: 'CLIENT_CHANGE',
	RESTART: "CLIENT_RESTART",
	SIDEBAR: 'CLIENT_SIDEBAR',
	SIDEBAR_FIELD: 'CLIENT_SIDEBAR_FIELD',
	HEADER: 'CLIENT_HEADER',
	CALENDAR: 'CLIENT_CALENDAR',
	POPULATE: 'CLIENT_POPULATE',
}

export const changeClient = newVars => ({
	type: CLIENT.CHANGE,
	newVars,
})

export const restartClient = () => ({
	type: CLIENT.RESTART,
})

export const setSidebarFor = id => ({
	type: CLIENT.SIDEBAR,
	id,
})

export const closeSidebar = () => ({
	type: CLIENT.SIDEBAR,
	id: undefined,
})

export const showSidebarMenu = (menuOpen=true) => ({
	type: CLIENT.SIDEBAR,
	menuOpen,
})

export const openSidebarField = field => ({
	type: CLIENT.SIDEBAR_FIELD,
	field,
})

export const showHeaderMenu = (menuOpen=true) => ({
	type: CLIENT.HEADER,
	menuOpen,
})

export const CALENDAR_ACTIONS = {
	GO_LEFT: 'GO_LEFT',
	GO_RIGHT: 'GO_RIGHT',
	ZOOM_IN: 'ZOOM_IN',
	ZOOM_OUT: 'ZOOM_OUT',
}

export const setCalendar = calendarInfo => ({
	type: CLIENT.CALENDAR,
	isAction: false,
	calendarInfo,
})

export const calendarAction = (calendarAction, isShiftPressed=false) => ({
	type: CLIENT.CALENDAR,
	isAction: true,
	calendarAction,
	isShiftPressed,
})

// replaces all existing client variables
export const populateClient = client => ({
	type: CLIENT.POPULATE,
	client,
})

// Task actions
export const TASK = {
	NEW: 'TASK_NEW',
	DELETE: 'TASK_DELETE',
	CHANGE: 'TASK_CHANGE',
	REPLACE_ALL: 'TASK_REPLACE_ALL',
}

export const newTask = (taskId, {streamId, startDate, duration, title="", ...otherInfo}) => ({
	type: TASK.NEW,
	taskId,
	taskInfo: {streamId, startDate, duration, title, ...otherInfo},
})

export const changeTask = (taskId, newInfo) => ({
	type: TASK.CHANGE,
	taskId,
	newInfo,
})

export const deleteTask = taskId => ({
	type: TASK.DELETE,
	taskId,
})

export const replaceAllTasks = tasks => ({
	type: TASK.REPLACE_ALL,
	tasks,
})

export const refreshTasks = () => ({
	type: TASK.REPLACE_ALL,
	tasks: {},
})

// Stream actions
export const STREAM = {
	NEW: 'STREAM_NEW',
	DELETE: 'STREAM_DELETE',
	CHANGE: 'STREAM_CHANGE',
	POPULATE: 'STREAM_POPULATE',
}

// streamInfo = { title, color }
export const newStream = (streamId, {title, color}) => ({
	type: STREAM.NEW,
	streamId,
	streamInfo: {title, color},
})

export const changeStream = (streamId, newInfo) => ({
	type: STREAM.CHANGE,
	streamId,
	newInfo,
})

export const deleteStream = streamId => ({
	type: STREAM.DELETE,
	streamId,
})

// replaces all current streams
export const populateStreams = streams => ({
	type: STREAM.POPULATE,
	streams,
})

export const refreshStreams = () => ({
	type: STREAM.POPULATE,
	streams: {},
})

// User actions
export const USER = {
	CHANGE: 'USER_CHANGE',
	POPULATE: 'USER_POPULATE',
}

export const changeUser = newInfo => ({
	type: USER.CHANGE,
	newInfo,
})

// replaces current user info
export const populateUser = user => ({
	type: USER.POPULATE,
	user,
})
