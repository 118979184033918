import React from 'react';
import './Icon.css';

const Icon = props => (
	<i
		className={
			"material-icons" +
			(props.clickable !== false ? " clickable" : "") +
			(props.hover ? " hover" : "")
		}
		onClick={props.onClick}
		style={props.style || {}}
		title={props.title}
	>{props.children}</i>
);

const IconWithLink = props => {
	const icon = <Icon {...props}> {props.children} </Icon>

	if (props.href) {
		return (
			<a href={props.href} style={{textDecoration: "none"}} target="_blank">
				{icon}
			</a>
		)
	} else {
		return icon
	}
}

export default IconWithLink;
