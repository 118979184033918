import React, { useEffect, useRef } from 'react'
import Header from '../../components/Header/Header'
import './SignIn.css'
import { EditTitle, Description } from '../../components/Sidebar/Parts/Parts'
import { withDatabase } from '../../database/context'

let TextField = props => (
	<div className="textfield-container">
		<div
			className="textfield"
			contentEditable
			suppressContentEditableWarning={true}
			placeholder={props.placeholder || ""}
			ref={props.refVar}
		/>
	</div>
)

let EmailField = props => (
	<div className="textfield-container">
		<input
			className="input-field"
			placeholder={props.placeholder}
			ref={props.refVar}
		/>
	</div>
)

let PasswordField = props => (
	<div className="textfield-container">
		<input
			className="input-field"
			type="password"
			placeholder={props.placeholder || "password"}
			ref={props.refVar}
		/>
	</div>
)

let SignIn = props => {

	const emailRef = useRef(null)
	const passwordRef = useRef(null)

	const onSignIn = () => props.database.signIn(emailRef.current.value, passwordRef.current.value).catch(e => alert(e.message))

	const onCreateAccount = () => props.database.createAccount(emailRef.current.value, passwordRef.current.value).catch(e => alert(e.message))

	const onKeyDown = event => {
		if (event.key === "Enter") onSignIn()
	}

	useEffect(() => {
		window.addEventListener("keydown", onKeyDown);
		return () => {
			window.removeEventListener("keydown", onKeyDown);
		}
	}, []);

	return (
		<div id="SignIn">
			<Header
				logoOnly={true}
			/>
			<div className="page-header">Sign In</div>
			<EmailField
				placeholder="email"
				refVar={emailRef}
			/>
			<PasswordField
				refVar={passwordRef}
			/>
			<div
				className="textfield-container"
				onClick={onSignIn}
			>
				<div className="textbutton medium">SIGN IN</div>
			</div>
			{/* <div
				className="textfield-container"
				onClick={onCreateAccount}
			>
				<div className="textbutton medium">CREATE ACCOUNT</div>
			</div> */}
			<div className="textfield-container bottom-text">
				<div style={{ marginBottom: "10px" }}>The website has now moved to the main effectivly.com domain!</div>
				<div><i>effectiv.ly also redirects here</i></div>
			</div>
		</div>
	)
}

export default withDatabase(SignIn)
