import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setCalendar, calendarAction, CALENDAR_ACTIONS } from '../redux/actions';

const withCalendar = AppComponent => {

	let Container = props => {

		// create new event listeners when state changes to capture new state
		useEffect(() => {
			window.addEventListener("keydown", onKeyDown);
			return () => {
				window.removeEventListener("keydown", onKeyDown);
			}
		}, [props.firstDay, props.numCols]);

		// handle arrow keys pressed
		const onKeyDown = event => {
			if (["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"].includes(event.key)) {
				moveCalendar(event.key);
			}
		}

		// change calendar on keydown
		const moveCalendar = direction => {
			switch (direction) {
				// go to earlier dates
				case "ArrowLeft":
					props.calendarAction(CALENDAR_ACTIONS.GO_LEFT, !window.isShiftPressed)
					break;
				// go to later dates
				case "ArrowRight":
					props.calendarAction(CALENDAR_ACTIONS.GO_RIGHT, !window.isShiftPressed)
					break;
				// zoom in
				case "ArrowUp":
					props.calendarAction(CALENDAR_ACTIONS.ZOOM_IN, window.isShiftPressed)
					break;
				// zoom out
				case "ArrowDown":
					props.calendarAction(CALENDAR_ACTIONS.ZOOM_OUT, window.isShiftPressed)
					break;
				default:
					break
			}
		}

		return (
			<React.Fragment>
				<AppComponent {...props} calendar={{ titleHeight: props.titleHeight }} />
			</React.Fragment>
		);
	}

	const mapStateToProps = state => state.client.calendar

	const mapDispatchToProps = dispatch => ({
		setCalendar: newInfo => dispatch(setCalendar(newInfo)),
		calendarAction: (action, isShiftPressed=false) => dispatch(calendarAction(action, isShiftPressed)),
	})

	Container = connect(mapStateToProps, mapDispatchToProps)(Container)

	return Container;

}

export default withCalendar;