import { CALENDAR_ACTIONS } from "../actions"
import { getNumDaysFromNum } from "../../functions/dates";
// default number of days to snap to if shift is not pressed
const calendarSnapSizes = [1, 3, 7, 14, 30];

export const handleCalendarAction = (state, action, isShiftPressed=false) => {
	switch (action) {
		case CALENDAR_ACTIONS.GO_LEFT:
			return {
				...state,
				firstDay: getNumDaysFromNum(state.firstDay, (isShiftPressed ? -1 : -state.numCols)).number,
			}
		case CALENDAR_ACTIONS.GO_RIGHT:
			return {
				...state,
				firstDay: getNumDaysFromNum(state.firstDay, (isShiftPressed ? 1 : state.numCols)).number,
			}
		case CALENDAR_ACTIONS.ZOOM_IN: {
			let newNum = state.numCols;
			let nextSmaller = calendarSnapSizes[0];
			for (let i = calendarSnapSizes.length - 1; i >= 0; i--) {
				nextSmaller = calendarSnapSizes[i];
				if (calendarSnapSizes[i] < state.numCols) break;
			}
			if (isShiftPressed) {
				newNum = Math.max(newNum - 2, 1);
			} else if (nextSmaller === newNum) {
				newNum = Math.max(Math.floor(state.numCols / 2), 1);
			} else {
				newNum = nextSmaller;
			}
			const diff = Math.floor((state.numCols - newNum) / 2)
			return {
				...state,
				firstDay: getNumDaysFromNum(state.firstDay, diff).number,
				numCols: newNum,
			}
		}
		case CALENDAR_ACTIONS.ZOOM_OUT: {
			let newNum = state.numCols;
			let nextLarger = calendarSnapSizes[0];
			for (let i = 0; i < calendarSnapSizes.length; i++) {
				nextLarger = calendarSnapSizes[i];
				if (calendarSnapSizes[i] > state.numCols) break;
			}
			if (isShiftPressed) {
				newNum += 2;
			} else if (nextLarger <= newNum) {
				newNum = Math.min(state.numCols * 2, calendarSnapSizes[calendarSnapSizes.length - 1]);
			} else {
				newNum = nextLarger;
			}
			const diff = Math.floor((newNum - state.numCols) / 2)
			return {
				...state,
				firstDay: getNumDaysFromNum(state.firstDay, -diff).number,
				numCols: newNum,
			}
		}
	}
}