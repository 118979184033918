import React from 'react'
import './Header.css'
import Icon from '../../pieces/Icon/Icon'
import Menu from '../../pieces/Menu/Menu'
import { connect } from 'react-redux'
import { showHeaderMenu, setCalendar, calendarAction, CALENDAR_ACTIONS } from '../../redux/actions'
import { numToDate, getNumDaysFromToday, getNumDaysFromNum } from '../../functions/dates'
import dateFormat from 'dateformat'
import { withDatabase } from '../../database/context'

let Header = props => {

	// get month text to show in title
	const getMonthText = () => {
		const firstDay = numToDate(props.calendarState.firstDay)
		const lastDay = getNumDaysFromNum(firstDay, props.calendarState.numCols - 1).date
		if (firstDay.getMonth() === lastDay.getMonth()) {
			return dateFormat(firstDay, "mmmm yyyy");
		}
		if (firstDay.getFullYear() === lastDay.getFullYear()) {
			return `${dateFormat(firstDay, "mmm")} - ${dateFormat(lastDay, "mmm yyyy")}`
		}
		return `${dateFormat(firstDay, "mmm yyyy")} - ${dateFormat(lastDay, "mmm yyyy")}`
	}

	if (props.logoOnly) {
		return (
			<div id="Header">
				{/* LOGO */}
				<div id="Logo-container">
					<div id="Logo" className="bold">effectivly</div>
					<div id="Badge">BETA</div>
				</div>
			</div>
		)
	}

	return (
		<div id="Header">
			{/* LOGO */}
			<div id="Logo-container">
				<div id="Logo" className="bold">effectivly</div>
				<div id="Badge">BETA</div>
			</div>

			<div id="header-left">
				{/* GO TO TODAY */}
				<Icon
					style={{fontSize: "19px"}}
					onClick={props.goToToday}
					title="Go to today"
				>calendar_today</Icon>

				{/* GO LEFT */}
				<Icon
					style={{fontSize: "27px"}}
					onClick={() => props.goLeft(CALENDAR_ACTIONS.GO_LEFT, window.isShiftPressed)}
					title="Move left in calendar"
				>chevron_left</Icon>

				{/* MONTH */}
				<div id="Title" className="bold">{getMonthText()}</div>

				{/* GO RIGHT */}
				<Icon
					style={{fontSize: "27px"}}
					onClick={() => props.goLeft(CALENDAR_ACTIONS.GO_RIGHT, window.isShiftPressed)}
					title="Move right in calendar"
				>chevron_right</Icon>

				{/* ZOOM IN */}
				<Icon
					style={{fontSize: "26px"}}
					onClick={() => props.goLeft(CALENDAR_ACTIONS.ZOOM_IN, window.isShiftPressed)}
					title="Zoom in to calendar"
				>zoom_in</Icon>

				{/* ZOOM OUT */}
				<Icon
					style={{fontSize: "26px"}}
					onClick={() => props.goLeft(CALENDAR_ACTIONS.ZOOM_OUT, window.isShiftPressed)}
					title="Zoom out from calendar"
				>zoom_out</Icon>

			</div>

			{/* SETTINGS */}
			<div id="header-right">
				<Icon
					style={{fontSize: "20px"}}
					title="Feedback"
					href={"https://forms.gle/BnLUrekdHRqM4Tej7"}
				>chat_bubble</Icon>
				<Menu
					id="settings_menu"
					show={props.menuOpen}
					actions={[
						// ["Account"],
						// ["Notifications"],
						// ["Preferences"],
						["Site tour", () => {
							props.hideMenu()
							if (props.popups) {
								props.database.updatePopups(["intro", ...props.popups])
							} else {
								props.database.updatePopups(["intro"])
							}
						}],
						["Sign out", props.database.signOut],
					]}
				>
					<Icon
						onClick={props.showMenu}
						hover={props.menuOpen}
					>settings</Icon>
				</Menu>
			</div>
		</div>
	);
}

const mapStateToProps = state => ({
	calendarState: state.client.calendar,
	menuOpen: state.client.header.menuOpen,
	popups: state.user.popups,
})

const mapDispatchToProps = dispatch => ({
	showMenu: () => dispatch(showHeaderMenu(true)),
	hideMenu: () => dispatch(showHeaderMenu(false)),
	goToToday: numDay => dispatch(setCalendar({
		firstDay: getNumDaysFromToday(numDay).number,
	})),
	goLeft: (action, isShiftPressed) => dispatch(calendarAction(action, isShiftPressed)),
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
	...stateProps,
	...dispatchProps,
	...ownProps,
	goToToday: () => {
		const third = Math.floor(stateProps.calendarState.numCols / 3)
		dispatchProps.goToToday(-Math.min(third, 3))
	},
})

Header = connect(mapStateToProps, mapDispatchToProps, mergeProps)(withDatabase(Header))

export default Header;
