import { getCurrentDate } from "./dates"

export const addTaskMethods = task => {
	if (!task.title) task.title = ""
	task.endDate = function () { return this.startDate + this.duration }
	task.conflictsWith = function (task) {
		if ((this.endDate() <= task.startDate) ||
			(this.startDate >= task.endDate())) {
			return false
		}
		return true
	}
	task.timeLeft = function () { return this.endDate() - getCurrentDate().number }
	task.ratioTimeLeft = function () { return (this.endDate() - getCurrentDate().number) / this.duration }
	task.ratioTimePassed = function () { return 1 - ((this.endDate() - getCurrentDate().number) / this.duration) }
	task.isCurrent = function () { return getCurrentDate().number >= this.startDate && getCurrentDate().number <= this.endDate() }
	task.isPast = function () { return getCurrentDate().number > this.endDate() }
	task.isFuture = function () { return getCurrentDate().number < this.startDate }
	task.toDB = function () {
		const retJSON = {
			duration: this.duration,
			startDate: this.startDate,
			streamId: this.streamId,
			title: this.title,
		}
		if (this.description) retJSON.description = this.description
		if (this.complete) retJSON.complete = true
		return retJSON
	}
	return task
}

