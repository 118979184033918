import React from 'react'
import { endOfScreen, getCurrentDate, CALENDAR_STEP_SIZES, millisecondsOnScreen } from '../../functions/dates'
import { STREAM_COLORS, COMPLETED_COLOR } from '../Streams/Streams'
import { connect } from 'react-redux'
import { setSidebarFor } from '../../redux/actions'
import { focusOnTitle } from '../../functions/sidebar'
import { runAtMostEvery } from '../../functions/general'

const Task = props => {
	const task = props.task
	const taskId = props.taskId
	const curDate = props.curDate

	const msToPercentage = ms => ((100 * ms) / millisecondsOnScreen(props.calendar))

	const widthBefore = msToPercentage(Math.max(task.startDate - curDate, 0))

	const widthAfter = (props.isLastInTrack ?
		msToPercentage(Math.max(
			endOfScreen(props.calendar) - task.endDate(),
			0
		)) :
		0
	)

	const timeBeforeCalendar = (props.isFirstInTrack ?
		Math.max((props.calendar.firstDay - task.startDate), 0) :
		0
	)

	const timeAfterCalendar = (props.isLastInTrack ?
		Math.max(task.endDate() - endOfScreen(props.calendar), 0) :
		0
	)

	const width = msToPercentage(
		Math.min(
			task.duration - timeBeforeCalendar - timeAfterCalendar,
			endOfScreen(props.calendar) - curDate
		)
	)

	// DEBUGGING, don't remove pls
	// if (props.tempTask) {
	// 	console.log({
	// 		widthBefore,
	// 		widthAfter,
	// 		timeAfterCalendar: timeBeforeCalendar / CALENDAR_STEP_SIZES.DAY,
	// 		timeBeforeCalendar: timeAfterCalendar / CALENDAR_STEP_SIZES.DAY,
	// 		width,
	// 		duration: task.duration / CALENDAR_STEP_SIZES.DAY,
	// 		title: task.title,
	// 		sss: (task.endDate() - endOfScreen(props.calendar)) / CALENDAR_STEP_SIZES.DAY,
	// 		ttt: props.isLastInTrack,
	// 		id: taskId,
	// 	})
	// }

	const leftCutOff = timeBeforeCalendar > 0
	const rightCutOff = timeAfterCalendar > 0

	const isSelected = !props.tempTask && props.sidebarId === taskId
	const isInFuture = task.isFuture()
	const isDragged = props.draggedTaskId && (props.draggedTaskId === taskId)
	let backColor = !task.complete ? (!isInFuture ? STREAM_COLORS[props.stream.color].dark : STREAM_COLORS[props.stream.color].light) : COMPLETED_COLOR
	let color = !task.complete ? (!isInFuture ? "#ffffff" : STREAM_COLORS[props.stream.color].dark) : STREAM_COLORS[props.stream.color].dark
	if (!task.title && !task.tempTitle) color += "80"

	const onClick = event => {
		event.stopPropagation()
		if (props.tempTask) return

		runAtMostEvery(props.taskClicked)
	}

	const onDoubleClick = event => {
		focusOnTitle()
		event.stopPropagation()
	}

	return (
		<div
			className={"task" +
				(isSelected ? " selected" : "") +
				(task.complete ? " complete" : "") +
				(isInFuture ? " future" : "")
			}
			style={{
				width: `calc(${width}% - 4px)`,
				marginLeft: `calc(${widthBefore}% + 2px)`,
				marginRight: `calc(${widthAfter}% + 2px)`,
				visibility: !isDragged ? "visible" : "hidden",
				// transitionDuration: "0s",
			}}
			onClick={onClick}
			onDoubleClick={onDoubleClick}
			onDragStart={(event) => props.onDragStart(event, taskId)}
			onDrag={props.onDrag}
			onDragEnd={props.onDragEnd}
			draggable="true"
			onMouseDown={() => window.mouseDownTaskId = taskId}
			onMouseUp={() => window.mouseDownTaskId = undefined}
		>
			<div
				className={"left resize-task" + (leftCutOff ? " cutoff" : "")}
				style={{
					backgroundColor: (leftCutOff ? "" : backColor),
					borderRight: (leftCutOff ? `15px solid ${backColor}` : ""),
				}}
			/>
			<div
				className={
					"title"
					// + (isSelected && !task.complete && !isInFuture ? " medium" : "")
				}
				style={{ backgroundColor: backColor, color: color }}
			>
				{task.tempTitle || task.title || "Unnamed deadline"}
				<div
					className="fader"
					style={{
						backgroundImage: `linear-gradient(to right, transparent, ${backColor})`
					}}
				></div>
			</div>
			<div
				className={"right resize-task" + (rightCutOff ? " cutoff" : "")}
				style={{
					backgroundColor: (rightCutOff ? "" : backColor),
					borderLeft: (rightCutOff ? `15px solid ${backColor}` : ""),
				}}
			/>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => {
	let task;
	if (ownProps.tempTask) {
		task = ownProps.task
	} else {
		task = state.tasks[ownProps.taskId]
	}
	return {
		task,
		calendar: state.client.calendar,
		stream: state.streams[task.streamId],
		sidebarId: state.client.sidebar.id,
	}
}

const mapDispatchToProps = (dispatch, ownProps) => ({
	taskClicked: () => dispatch(setSidebarFor(ownProps.taskId))
})

export default connect(mapStateToProps, mapDispatchToProps)(Task)