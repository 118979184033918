import { TASK } from "../actions"
import { addTaskMethods } from "../../functions/tasks"

const SAMPLE_TASKS = {}
// {
// 	101: {title: "miniproject", streamId: "0", startDate: -2, duration: 7},
// 	// 102: {title: "miniproject", streamId: 0, startDate: -2, duration: 7, complete: true},
// 	103: {title: "watch lecture", streamId: "0", startDate: 4, duration: 3},
// 	104: {title: "write memo", streamId: "1", startDate: -5, duration: 7},
// 	105: {title: "write essay", streamId: "2", startDate: 0, duration: 5},
// }

const newTaskReducer = (state, action) => ({
	...state,
	[action.taskId]: addTaskMethods(action.taskInfo)
})

const changeTaskReducer = (state, action) => ({
	...state,
	[action.taskId]: {
		...state[action.taskId],
		...action.newInfo,
	}
})

const deleteTaskReducer = (state, action) => {
	const {
		[action.taskId]: _,
		...newState
	} = state
	return newState
}

const tasks = (state = SAMPLE_TASKS, action) => {
	switch (action.type) {
		case TASK.NEW:
			return newTaskReducer(state, action)
		case TASK.CHANGE:
			return changeTaskReducer(state, action)
		case TASK.DELETE:
			return deleteTaskReducer(state, action)
		case TASK.REPLACE_ALL:
			return action.tasks
		default:
			return state
	}
}

export default tasks