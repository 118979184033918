import React, { useRef } from 'react'
import { useState } from 'react'
import { isStringValidNumber, VALID_STRING, stopPropagateButEscape } from '../../../functions/sidebar'

const NumberPicker = props => {

	const [isValid, setValidity] = useState(VALID_STRING)

	const update = (addToLargerUnit = 0, overrideIfInvalid=false) => {
		let num = numRef.current.innerText
		const validity = isStringValidNumber(num, props.min, props.max)
		if (validity.number !== undefined) {
			props.onChange(validity.number, addToLargerUnit)
		} else {
			if (overrideIfInvalid) {
				numRef.current.innerText = props.value
				setValidity(VALID_STRING)
				return
			} else {
				props.setInvalid()
			}
			setValidity(validity)
		}
	}

	const numRef = useRef(null)

	return (
		<div className="sidebarParts numberpicker">
			<div className="picker">
				<div
					className="number textfield"
					contentEditable
					suppressContentEditableWarning={true}
					onKeyDown={stopPropagateButEscape}
					onKeyUp={() => update()}
					onBlur={() => update(0, true)}
					ref={numRef}
				>
					{props.value || 0}
				</div>
				<div
					className={"button" + (isValid.validNum ? "" : " disabled")}
					onClick={() => {
						if (parseInt(numRef.current.innerText) === props.min) {
							numRef.current.innerText = props.max
							update(-1)
							return
						} else {
							numRef.current.innerText = parseInt(numRef.current.innerText) - 1
						}
						update()
					}}
				>-</div>
				<div
					className={"button" + (isValid.validNum ? "" : " disabled")}
					onClick={() => {
						if (parseInt(numRef.current.innerText) === props.max) {
							numRef.current.innerText = props.min
							update(1)
							return
						} else {
							numRef.current.innerText = parseInt(numRef.current.innerText) + 1
						}
						update()
					}}
				>+</div>
				{props.unit &&
					<div className="unit">
						{props.unit}
					</div>
				}
			</div>
			{!isValid.inRange &&
				<div className="error">must be a number between {props.min} and {props.max}</div>
			}
		</div>
	)
}

export default NumberPicker