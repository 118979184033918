import { CLIENT } from "../actions"
import { getNumDaysFromToday, CALENDAR_STEP_SIZES } from "../../functions/dates"
import { handleCalendarAction } from "./handleCalendar"

const INIT_CLIENT_STATE = {
	sidebar: {
		id: undefined,
		menuOpen: false,
	},
	calendar: {
		firstDay: getNumDaysFromToday(-3).number,
		numCols: 14,
		stepSize: CALENDAR_STEP_SIZES.DAY,
		titleHeight: 30,
	},
	header: {
		menuOpen: false,
	},
	streams: {
		isScrollbar: false,
	},
	streamOrder: [],
}

const sidebar = (state={id: undefined, menuOpen: false}, action) => {

	const {type, ...newInfo} = action
	let newState = { ...state, ...newInfo }
	if (![true, false].includes(action.menuOpen) && newState.id === state.id) newState.id = undefined
	if (newState.id !== state.id && newState.field) delete newState.field
	if (newState.id === undefined) newState = {id: undefined, menuOpen: false}

	return newState
}

const calendar = (state, action) => {
	if (!action.isAction) {
		return {
			...state,
			...action.calendarInfo,
		}
	} else {
		return handleCalendarAction(state, action.calendarAction, action.isShiftPressed)
	}
}

const header = (state, action) => {
	if ([true, false].includes(action.menuOpen)) {
		return {
			...state,
			menuOpen: action.menuOpen,
		}
	}
	return { menuOpen: false }
}

const client = (state = INIT_CLIENT_STATE, action) => {
	switch(action.type) {
		case CLIENT.CHANGE:
			return {
				...state,
				...action.newVars
			}
		case CLIENT.RESTART:
			return INIT_CLIENT_STATE
		case CLIENT.SIDEBAR:
			return {
				...state,
				sidebar: sidebar(state.sidebar, action),
			}
		case CLIENT.SIDEBAR_FIELD:
			return {
				...state,
				sidebar: {
					...state.sidebar,
					field: action.field,
				},
			}
		case CLIENT.CALENDAR:
			return {
				...state,
				calendar: calendar(state.calendar, action),
			}
		case CLIENT.HEADER:
			return {
				...state,
				header: header(state.header, action)
			}
		case CLIENT.POPULATE:
			return action.client
		default:
			return state
	}
}

export default client