import React, { useState, useRef } from 'react'
import './Parts.css'
import { isStringValidNumber } from '../../../functions/sidebar'

export const Description = props => {
	if (!props.editMode) {
		if (props.text && props.text.length > 0) {
			return (
				<div
					className="sidebarParts description small"
					onClick={props.onClick}
				>
					{props.text}
				</div>
			)
		}
		// empty description, add placeholder
		return (
			<div
				className="sidebarParts description small placeholder"
				onClick={props.onClick}
			>
				{props.placeholder}
			</div>
		)
	}

	// description in edit mode
	return (
		<textarea
			className="sidebarParts editDescription"
			placeholder={props.placeholder || "Description"}
			rows="6"
			autoFocus={true}
			onBlur={props.exit}
			ref={props.refVar}
			defaultValue={props.text}
			onKeyDown={event => {
				if (event.key === "Escape") return
				event.stopPropagation()
			}}
		/>
	)
}

export const EditTitle = props => (
	<div
		className="sidebarParts title textfield"
		contentEditable placeholder={props.placeholder || ""}
		suppressContentEditableWarning={true}
		tabIndex={0}
		onBlur={props.onBlur}
		ref={props.refVar}
		onKeyUp={props.onKeyUp}
		onKeyDown={event => {
			if (event.key === "Escape") {
				!props.discardChanges || props.discardChanges()
				event.target.blur()
			}
			event.stopPropagation()
		}}
	>{props.value}</div>
)

export const FormGroup = props => (
	<div className="sidebarParts formgroup">{props.children}</div>
)

export const Heading = props => (
	<div className="sidebarParts heading small bold">{props.value}</div>
)

export const TextInfo = props => {
	return (
		<div
			className="sidebarParts textinfo small"
		>{props.children}</div>
	)
}

export const ButtonField = props => {
	return (
		<div
			className={
				"sidebarParts buttonfield small" +
				(props.selected ? " selected" : "")
			}
			onClick={props.onClick}
		>{props.children}</div>
	)
}

export const ButtonWithColor = props => {
	return (
		<ButtonField onClick={props.onClick} selected={props.selected}>
			<div className="color" style={{ backgroundColor: props.color }} />
			<div className="value">
				{props.children}
			</div>
		</ButtonField>
	)
}

export const ConfirmFieldButtons = props => (
	<div className="sidebarParts actions medium">
		{props.onLeftButtonClicked &&
			<div
				className={
					"action" +
					(props.leftButtonDisabled ? " disabled" : "")
				}
				onClick={props.onLeftButtonClicked}
			>{props.buttonLeftText || "CANCEL"}</div>
		}
		{props.onLeftButtonClicked && props.onRightButtonClicked &&
			<div className="spacer" />
		}
		{props.onRightButtonClicked &&
			<div
				className={
					"action" +
					(props.rightButtonDisabled ? " disabled" : "")
				}
				onClick={props.onRightButtonClicked}
			>{props.buttonRightText || "OKAY"}</div>
		}
	</div>
)
