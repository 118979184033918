import React, { useRef } from 'react'
import { connect } from 'react-redux'
import { Heading, ButtonField, FormGroup, ButtonWithColor, TextInfo, Description } from '../Parts/Parts'
import { STREAM_COLORS } from '../../Streams/Streams'
import { withDatabase } from '../../../database/context'
import { msToUnits, getCurrentDate, decimalToPercent, CALENDAR_STEP_SIZES, getDateTextFromNum } from '../../../functions/dates'
import { openSidebarField } from '../../../redux/actions'
import DurationPicker from './DurationPicker'
import IfElse from '../../../pieces/IfElse/IfElse'
import DatetimePicker from './DatetimePicker/DatetimePicker'
import { addLinksToText } from '../../../functions/general'

const TaskContent = props => {

	const FIELDS = {
		CHOOSE_STREAM: 'CHOOSE_STREAM',
		DURATION: 'DURATION',
		START_DATE: 'START_DATE',
		END_DATE: 'END_DATE',
		DESCRIPTION: 'DESCRIPTION',
	}

	const duration = msToUnits(props.task.duration)

	const APPROX_LEVELS = {
		EXACT: 0,
		TWO_UNITS: 1,
		ONE_UNIT: 2,
		HOURS: 3,
		DAYS: 4,
	}

	const getDurationText = (units=duration, approx=APPROX_LEVELS.EXACT) => {
		let retString = ""
		if (units.days) {
			retString += `${units.days} day${units.days > 1 ? "s" : ""}`
		}
		if (approx === APPROX_LEVELS.DAYS) {
			if (!units.days) {
				return 'Less than a day'
			}
			return retString
		}
		if (retString && approx === APPROX_LEVELS.ONE_UNIT) return retString
		if (units.hours) {
			if (retString) retString += ', '
			retString += `${units.hours} hour${units.hours > 1 ? "s" : ""}`
		}
		if (retString && approx === APPROX_LEVELS.ONE_UNIT) return retString
		if (units.minutes) {
			if (approx === APPROX_LEVELS.TWO_UNITS && units.days && units.hours) {
				return retString
			}
			if (approx === APPROX_LEVELS.HOURS) {
				if (!units.days && !units.hours) {
					return 'Less than one hour'
				}
				if (!units.days) {
					return `Less than ${units.hours + 1} hours`
				}
				return retString
			}
			if (retString) retString += ', '
			retString += `${units.minutes} minute${units.minutes > 1 ? "s" : ""}`
		}
		return retString
	}

	const descRef = useRef(null)

	return (
		<React.Fragment>

			<FormGroup>
				<Heading value="Description" />
				<IfElse
					if={props.fieldInFocus !== FIELDS.DESCRIPTION}
					then={
						<Description
							placeholder="Additional task details, links, etc."
							editMode={false}
							text={addLinksToText(props.task.description)}
							onClick={() => props.openSidebarField(FIELDS.DESCRIPTION)}
						/>
					}
					else={
						<Description
							placeholder="Additional task details, links, etc."
							editMode={true}
							text={props.task.description}
							exit={() => {
								props.database.changeTask(props.taskId, { description: descRef.current.value })
								props.openSidebarField()
							}}
							refVar={descRef}
						/>
					}
				/>
			</FormGroup>

			<FormGroup>
				<Heading value="Assigned on" />
				<IfElse
					if={props.fieldInFocus !== FIELDS.START_DATE}
					then={
						<ButtonField
							onClick={() => props.openSidebarField(FIELDS.START_DATE)}
						>
							{getDateTextFromNum(props.task.startDate)}
						</ButtonField>
					}
					else={
						<React.Fragment>
							<ButtonField
								onClick={props.openSidebarField}
							>
								{getDateTextFromNum(props.task.startDate)}
							</ButtonField>
							<DatetimePicker
								currentDate={props.task.startDate}
								onSelect={time => {
									props.database.changeTask(props.taskId, {startDate: time})
								}}
								closeField={props.openSidebarField}
							/>
						</React.Fragment>
					}
				/>
			</FormGroup>

			<FormGroup>
				<Heading value="Due on" />
				<IfElse
					if={props.fieldInFocus !== FIELDS.END_DATE}
					then={
						<ButtonField
							onClick={() => props.openSidebarField(FIELDS.END_DATE)}
						>
							{getDateTextFromNum(props.task.startDate + props.task.duration, 1)}
						</ButtonField>
					}
					else={
						<React.Fragment>
							<ButtonField
								onClick={props.openSidebarField}
							>
								{getDateTextFromNum(props.task.startDate + props.task.duration, 1)}
							</ButtonField>
							<DatetimePicker
								currentDate={props.task.endDate() - 1}
								onSelect={time => {
									props.database.changeTask(props.taskId, {duration: (time + CALENDAR_STEP_SIZES.DAY) - props.task.startDate})
								}}
								closeField={props.openSidebarField}
								disableBefore={props.task.startDate}
							/>
						</React.Fragment>
					}
				/>
			</FormGroup>

			<FormGroup>
				<Heading value="Duration" />
				<IfElse
					if={props.fieldInFocus !== FIELDS.DURATION}
					then={
						<ButtonField
							onClick={() => props.openSidebarField(FIELDS.DURATION)}
						>
							{getDurationText(duration, APPROX_LEVELS.DAYS)}
						</ButtonField>
					}
					else={
						<React.Fragment>
							<ButtonField
								onClick={props.openSidebarField}
							>
								{getDurationText(duration, APPROX_LEVELS.DAYS)}
							</ButtonField>
							<DurationPicker
								duration={duration}
								closeField={props.openSidebarField}
								changeDuration={duration => props.database.changeTask(props.taskId, {duration})}
							/>
						</React.Fragment>
					}
				/>
			</FormGroup>

			<FormGroup>
				<Heading value="Stream" />
				<IfElse
					if={props.fieldInFocus !== FIELDS.CHOOSE_STREAM}
					then={
						<ButtonWithColor
							color={STREAM_COLORS[props.stream.color].dark}
							onClick={() => props.openSidebarField(FIELDS.CHOOSE_STREAM)}
						>
							{props.stream.title}
						</ButtonWithColor>
					}
					else={
						<div className="basicOptions">
							{[props.task.streamId, ...Object.keys(props.streams).filter(id => id !== props.task.streamId)].map(streamId => {
								const stream = props.streams[streamId]
								return (
									<ButtonWithColor
										key={streamId}
										color={STREAM_COLORS[stream.color].dark}
										onClick={() => {
											props.openSidebarField()
											if (streamId === props.task.streamId) return
											props.database.changeTask(props.taskId, { streamId: streamId })
										}}
										selected={streamId === props.task.streamId}
									>
										{stream.title}
									</ButtonWithColor>
								)
							})}
						</div>
					}
				/>
			</FormGroup>


			{props.task.isCurrent() &&
				<React.Fragment>
					<FormGroup>
						<Heading value="Percent time passed" />
						<TextInfo>{decimalToPercent(props.task.ratioTimePassed())}%</TextInfo>
					</FormGroup>
					<FormGroup>
						<Heading value="Time until deadline" />
						<TextInfo>{getDurationText(msToUnits(props.task.timeLeft()), APPROX_LEVELS.HOURS)}</TextInfo>
					</FormGroup>
				</React.Fragment>
			}

			{!props.task.complete && props.task.isPast() &&
				<FormGroup>
					<Heading value="Time until deadline" />
					<TextInfo>Overdue</TextInfo>
				</FormGroup>
			}

			{props.task.isFuture() &&
				<FormGroup>
					<Heading value="Time until assigned" />
					<TextInfo>{getDurationText(msToUnits(props.task.startDate - getCurrentDate().number), APPROX_LEVELS.DAYS)}</TextInfo>
				</FormGroup>
			}
		</React.Fragment>
	)
}

const mapStateToProps = state => {
	const taskId = state.client.sidebar.id
	const task = state.tasks[taskId]

	return {
		taskId: taskId,
		task: task,
		stream: state.streams[task.streamId],
		streams: state.streams,
		fieldInFocus: state.client.sidebar.field,
	}
}

const mapDispatchToProps = dispatch => ({
	openSidebarField: field => dispatch(openSidebarField(field)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withDatabase(TaskContent))
