export const sortByStreamOrder = (allStreamIds, streamOrder) => {
	const orderedStreams = []
	const otherStreams = []
	for (let streamId of streamOrder) {
		if (orderedStreams.includes(streamId)) continue
		if (allStreamIds.includes(streamId)) {
			orderedStreams.push(streamId)
		}
	}
	for (let streamId of allStreamIds.sort()) {
		if (otherStreams.includes(streamId)) continue
		if (!streamOrder.includes(streamId)) {
			otherStreams.push(streamId)
		}
	}
	return orderedStreams.concat(otherStreams)
}
