import React from 'react';
import './Menu.css';

const Menu = props => {
	return (
		<div className={"menu container medium" + (props.hover ? " hover" : "") + (props.show ? " show" : "")} id={props.id}>
			{props.children}
			<div className="Menu" onClick={props.onClick}>
				<div className="menu content">
					{props.actions.map((action, i) => {
						const isDisabled = action.length < 2;
						return (
							<div key={i} className={"menu option" + (isDisabled ? " disabled" : "")} onClick={isDisabled ? () => {} : action[1]}>{action[0]}</div>
						)
					})}
				</div>
			</div>
		</div>
	);
}

export default Menu;
